import React from "react"
import { useAuth } from "@/containers/authData"
import env from "@/env"
import Script from "next/script"

export const UserDotCom = () => {
    const { userData, isImpersonating } = useAuth()

    const handleOnLoad = React.useCallback(() => {
        window.userengage?.("widget.hide")
    }, [])

    const userDotComApiKey = env.userDotComApiKey
    const userDotComDomain = env.userDotComDomain

    if (!userDotComApiKey) {
        return null
    }

    if (isImpersonating) {
        return null
    }

    if (!userData) {
        return null
    }

    return (
        <>
            <Script id="user_dot_com" async strategy="afterInteractive">
                {`
                window.civchat = {
                    apiKey: "${userDotComApiKey}",
                  };
                `}
            </Script>
            <Script
                id="user_dot_com_external"
                async
                strategy="afterInteractive"
                src={`https://${userDotComDomain}/widget.js`}
                onLoad={handleOnLoad}
            />
        </>
    )
}
