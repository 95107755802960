const localStorageSSRFallback: Storage = {
    getItem: () => null,
    setItem: () => null,
    length: 0,
    clear: () => null,
    key: () => null,
    removeItem: () => null,
}

export const localStorage = global.localStorage || localStorageSSRFallback
