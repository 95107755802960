import React from "react"
import { useLocalStorage } from "@/hooks/useLocalStorage"
import { unpackQueryArray } from "@/utils"
import { Router, useRouter } from "next/router"

import { FEATURE_FLAGS_WHITELIST } from "./whitelist"

export const useFeatureFlag = (flagName: FeatureFlagName) => {
    return React.useContext(FeatureFlagsContext)[flagName] || false
}

export type FeatureFlagsProviderProps = {
    children: React.ReactNode
}

export const FeatureFlagsProvider = ({ children }: FeatureFlagsProviderProps) => {
    const router = useRouter()

    const [featureFlags, setFeatureFlags] = useLocalStorage<FeatureFlags>(
        FEATURE_FLAGS_LOCAL_STORAGE_KEY,
        createEmptyFeatureFlagsObject
    )

    const featureFlagChanges = React.useMemo(
        () => computeFeatureFlagChangesBasedOnQueryParams(router.query),
        [router.query]
    )

    React.useEffect(() => {
        featureFlagChanges.forEach(([featureFlagName, booleanValue]) => {
            setFeatureFlags(featureFlags => ({ ...featureFlags, [featureFlagName]: booleanValue }))
        })
    }, [featureFlagChanges, setFeatureFlags])

    React.useEffect(() => {
        if (featureFlagChanges.length === 0) return

        const { pathname, query } = router

        const newQuery = { ...query }
        featureFlagChanges.forEach(([featureFlagName]) => {
            delete newQuery[`feat${featureFlagName}`]
        })

        router.replace({ pathname, query: newQuery }, undefined, { shallow: true })
    }, [router, featureFlagChanges])

    return <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>
}

const possibleQueryParamNames = FEATURE_FLAGS_WHITELIST.map(featureFlag => `feat${featureFlag}`)
const possibleQueryParamValues = ["true", "false"]

type FeatureFlagName = typeof FEATURE_FLAGS_WHITELIST[number]

type FeatureFlags = { [P in FeatureFlagName]: boolean }

const createEmptyFeatureFlagsObject = () =>
    Object.fromEntries(FEATURE_FLAGS_WHITELIST.map(flag => [flag, false])) as FeatureFlags

const FeatureFlagsContext = React.createContext<FeatureFlags>(createEmptyFeatureFlagsObject())

const FEATURE_FLAGS_LOCAL_STORAGE_KEY = "heroify_feature_flags"

const computeFeatureFlagChangesBasedOnQueryParams = (queryParams: Router["query"]) =>
    Object.entries(queryParams).flatMap(([queryParamName, queryParamValue]) => {
        const unpackedQueryParamValue = unpackQueryArray(queryParamValue)

        if (unpackedQueryParamValue === undefined) return []
        if (possibleQueryParamValues.indexOf(unpackedQueryParamValue) === -1) return []
        if (possibleQueryParamNames.indexOf(queryParamName) === -1) return []

        return [[queryParamName.replace("feat", "") as FeatureFlagName, unpackedQueryParamValue === "true"] as const]
    })
