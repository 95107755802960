import React from "react"

import { ICONS } from "./icons"

export type IconName = keyof typeof ICONS

export type IconProps = {
    name: IconName
    className?: string
} & React.ComponentProps<"svg">

export const Icon = React.forwardRef<HTMLElement, IconProps>(({ name, ...rest }, ref) => {
    const Component = ICONS[name]

    return <Component ref={ref} {...rest} />
})
