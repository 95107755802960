import styled, { css } from "styled-components"

type CSSValue = string | number

// todo: turn it into Chakra-UI-like Box API
// type RWDCSSValue = Partial<Record<Breakpoint, CSSValue>>

type SpaceProps = {
    m?: CSSValue /* | RWDCSSValue */
    mx?: CSSValue /* | RWDCSSValue */
    my?: CSSValue /* | RWDCSSValue */
    p?: CSSValue /* | RWDCSSValue */
    px?: CSSValue /* | RWDCSSValue */
    py?: CSSValue /* | RWDCSSValue */
}

type CSSSpaceProperty =
    | "margin-left"
    | "margin-right"
    | "margin"
    | "margin-top"
    | "margin-bottom"
    | "padding-left"
    | "padding-right"
    | "padding"
    | "padding-top"
    | "padding-bottom"

type SpacePropsSpaceMap = Record<keyof SpaceProps, [CSSSpaceProperty, CSSSpaceProperty?]>

const cssSpaceMap: SpacePropsSpaceMap = {
    m: ["margin"],
    mx: ["margin-left", "margin-right"],
    my: ["margin-top", "margin-bottom"],
    p: ["padding"],
    px: ["padding-left", "padding-right"],
    py: ["padding-top", "padding-bottom"],
}

type BoxProps = SpaceProps & {
    bgColor?: string
    fullH?: boolean
    fullW?: boolean
    minWidth?: string
    minHeight?: string
    maxWidth?: string
    maxHeight?: string
}

export const Box = styled.div<BoxProps>`
    /* // todo: improve */
    ${p =>
        p.m &&
        cssSpaceMap.m.map(
            property => css`
                ${property}: ${p.m};
            `
        )}

    ${p =>
        p.mx &&
        cssSpaceMap.mx.map(
            property => css`
                ${property}: ${p.mx};
            `
        )}

    ${p =>
        p.my &&
        cssSpaceMap.my.map(
            property => css`
                ${property}: ${p.my};
            `
        )}

    ${p =>
        p.p &&
        cssSpaceMap.p.map(
            property => css`
                ${property}: ${p.p};
            `
        )}

    ${p =>
        p.px &&
        cssSpaceMap.px.map(
            property => css`
                ${property}: ${p.px};
            `
        )}

    ${p =>
        p.py &&
        cssSpaceMap.py.map(
            property => css`
                ${property}: ${p.py};
            `
        )}

    ${p =>
        p.bgColor &&
        css`
            background-color: ${p.bgColor};
        `}

    ${p =>
        p.fullH &&
        css`
            height: 100%;
        `}
    
    ${p =>
        p.fullW &&
        css`
            width: 100%;
        `}

    ${p =>
        p.minWidth &&
        css`
            min-width: ${p.minWidth};
        `}
    
    ${p =>
        p.minHeight &&
        css`
            min-height: ${p.minHeight};
        `}


        ${p =>
        p.maxWidth &&
        css`
            max-width: ${p.maxWidth};
        `}
    
    ${p =>
        p.maxHeight &&
        css`
            max-height: ${p.maxHeight};
        `}
`
