import React from "react"
import env from "@/env"
import { useRouter } from "next/router"
import Script from "next/script"

export const GoogleTagManger = () => {
    const history = React.useMemo<string[]>(() => [], [])
    const router = useRouter()

    React.useEffect(() => {
        router.events.on("routeChangeComplete", (url: string) => {
            history.push(url)
        })
    }, [router, history])

    return (
        <>
            {env.gtmId && (
                <Script id="google_tag_manager" async strategy="afterInteractive">
                    {`(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${env.gtmId}');
                    `}
                </Script>
            )}
        </>
    )
}
