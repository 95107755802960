import React from "react"
import { localStorage } from "@/utils/storage"

export const useLocalStorage = <S>(key: string, initialState: S | (() => S)) => {
    const [state, setState] = React.useState<S>(() => {
        const localStorageState = localStorage.getItem(key)

        if (localStorageState !== null) {
            return JSON.parse(localStorageState)
        }

        if (initialState instanceof Function) {
            return initialState()
        }

        return initialState
    })

    React.useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state))
    }, [key, state])

    return [state, setState] as const
}
