/* eslint-disable */

import { Zeus, GraphQLTypes, InputType, ValueTypes, OperationOptions, ZeusScalars } from './zeus';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import type { QueryHookOptions, LazyQueryHookOptions, MutationHookOptions } from '@apollo/client';

export const scalars = ZeusScalars({
    Decimal: {
        decode: (e: unknown) => parseFloat(e as string),
    },
    UUID: {
        decode: (e: unknown) => e as string,
    },
    DateTime: {
        decode: (e: unknown) => e as string,
    },
})

export function useTypedQuery<Z extends ValueTypes[O], O extends "Query", SCLR extends typeof scalars>(
  query: Z | ValueTypes[O],
  options?:{
    apolloOptions?: QueryHookOptions<InputType<GraphQLTypes[O], Z, SCLR>>,
    operationOptions?: OperationOptions,
  }
) {
  return useQuery<InputType<GraphQLTypes[O], Z, SCLR>>(gql(Zeus("query",query, {
    operationOptions: options?.operationOptions,
    scalars
  })), { ...(options?.apolloOptions ?? {}), context: { newApi: true }});
}
export function useTypedLazyQuery<Z extends ValueTypes[O], O extends "Query", SCLR extends typeof scalars>(
  LazyQuery: Z | ValueTypes[O],
  options?:{
    apolloOptions?: LazyQueryHookOptions<InputType<GraphQLTypes[O], Z, SCLR>>,
    operationOptions?: OperationOptions,
  }
) {
  return useLazyQuery<InputType<GraphQLTypes[O], Z, SCLR>>(gql(Zeus("query",LazyQuery, {
    operationOptions: options?.operationOptions,
    scalars,
  })), { ...(options?.apolloOptions ?? {}), context: { newApi: true }});
}
export function useTypedMutation<Z extends ValueTypes[O], O extends "Mutation", SCLR extends typeof scalars>(
  mutation: Z | ValueTypes[O],
  options?:{
    apolloOptions?: MutationHookOptions<InputType<GraphQLTypes[O], Z, SCLR>>,
    operationOptions?: OperationOptions,
  }
) {
  return useMutation<InputType<GraphQLTypes[O], Z, SCLR>>(gql(Zeus("mutation",mutation, {
    operationOptions: options?.operationOptions,
    scalars,
  })), { ...(options?.apolloOptions ?? {}), context: { newApi: true }});
}
