import { css, FlattenSimpleInterpolation } from "styled-components"

import { Theme } from "./theme"

export type Selector = (props: { theme: Theme }) => string
export type InterpolatedSelector = (props: { theme: Theme }) => FlattenSimpleInterpolation

export const palettePrimary100: Selector = p => p.theme.palette.primary["100"]
export const palettePrimary200: Selector = p => p.theme.palette.primary["200"]
export const palettePrimary400: Selector = p => p.theme.palette.primary["400"]
export const palettePrimary500: Selector = p => p.theme.palette.primary["500"]
export const palettePrimary700: Selector = p => p.theme.palette.primary["700"]
export const palettePrimaryContrastText: Selector = p => p.theme.palette.primary.contrastText

export const paletteSecondary100: Selector = p => p.theme.palette.secondary["100"]
export const paletteSecondary200: Selector = p => p.theme.palette.secondary["200"]
export const paletteSecondary400: Selector = p => p.theme.palette.secondary["400"]
export const paletteSecondary500: Selector = p => p.theme.palette.secondary["500"]
export const paletteSecondary700: Selector = p => p.theme.palette.secondary["700"]

export const paletteNeutral100: Selector = p => p.theme.palette.neutral["100"]
export const paletteNeutral200: Selector = p => p.theme.palette.neutral["200"]
export const paletteNeutral300: Selector = p => p.theme.palette.neutral["300"]
export const paletteNeutral400: Selector = p => p.theme.palette.neutral["400"]
export const paletteNeutral500: Selector = p => p.theme.palette.neutral["500"]
export const paletteNeutral700: Selector = p => p.theme.palette.neutral["700"]
export const paletteNeutral900: Selector = p => p.theme.palette.neutral["900"]

export const paletteError100: Selector = p => p.theme.palette.error["100"]
export const paletteError200: Selector = p => p.theme.palette.error["200"]
export const paletteError500: Selector = p => p.theme.palette.error["500"]
export const paletteError600: Selector = p => p.theme.palette.error["600"]
export const paletteError700: Selector = p => p.theme.palette.error["700"]

export const paletteWarning100: Selector = p => p.theme.palette.warning["100"]
export const paletteWarning400: Selector = p => p.theme.palette.warning["400"]
export const paletteWarning500: Selector = p => p.theme.palette.warning["500"]
export const paletteWarning600: Selector = p => p.theme.palette.warning["600"]
export const paletteWarning700: Selector = p => p.theme.palette.warning["700"]

export const paletteWhite: Selector = p => p.theme.palette.neutral.white

export const spacing1: Selector = p => p.theme.spacing["1"]
export const spacing2: Selector = p => p.theme.spacing["2"]
export const spacing3: Selector = p => p.theme.spacing["3"]
export const spacing4: Selector = p => p.theme.spacing["4"]
export const spacing5: Selector = p => p.theme.spacing["5"]
export const spacing6: Selector = p => p.theme.spacing["6"]
export const spacing7: Selector = p => p.theme.spacing["7"]
export const spacing8: Selector = p => p.theme.spacing["8"]
export const spacing9: Selector = p => p.theme.spacing["9"]
export const spacing10: Selector = p => p.theme.spacing["10"]
export const spacing12: Selector = p => p.theme.spacing["12"]
export const spacing15: Selector = p => p.theme.spacing["15"]
export const spacing20: Selector = p => p.theme.spacing["20"]

export const smallIconSize: Selector = p => p.theme.size.icon.small
export const generalIconSize: Selector = p => p.theme.size.icon.general
export const largeIconSize: Selector = p => p.theme.size.icon.large

export const borderRadiusSm: Selector = p => p.theme.size.borderRadius.sm
export const borderRadiusMd: Selector = p => p.theme.size.borderRadius.md
export const borderRadiusLg: Selector = p => p.theme.size.borderRadius.lg

const composeTypography = (typography: { size: string; weight: number }) => css`
    font-weight: ${typography.weight};
    font-size: ${typography.size};
`
export const typographyH1: InterpolatedSelector = p => composeTypography(p.theme.typography.h1)
export const typographyH2: InterpolatedSelector = p => composeTypography(p.theme.typography.h2)
export const typographyH3: InterpolatedSelector = p => composeTypography(p.theme.typography.h3)
export const typographyH4: InterpolatedSelector = p => composeTypography(p.theme.typography.h4)
export const typographyT1: InterpolatedSelector = p => composeTypography(p.theme.typography.t1)
export const typographyT1b: InterpolatedSelector = p => composeTypography(p.theme.typography.t1b)
export const typographyT2: InterpolatedSelector = p => composeTypography(p.theme.typography.t2)
export const typographyT2b: InterpolatedSelector = p => composeTypography(p.theme.typography.t2b)
export const typographyT3: InterpolatedSelector = p => composeTypography(p.theme.typography.t3)
export const typographyT3b: InterpolatedSelector = p => composeTypography(p.theme.typography.t3b)

export const primaryFont: Selector = p => p.theme.typography.font.primary

export const breakpointSm: Selector = p => p.theme.breakpoints.sm
export const breakpointMd: Selector = p => p.theme.breakpoints.md
export const breakpointLg: Selector = p => p.theme.breakpoints.lg

export const boxShadowSm: Selector = p => p.theme.boxShadow.sm
export const boxShadowMd: Selector = p => p.theme.boxShadow.md
export const boxShadowLg: Selector = p => p.theme.boxShadow.lg
export const boxShadowXl: Selector = p => p.theme.boxShadow.xl
