export const GlobalStyles = () => (
    // eslint-disable-next-line react/no-unknown-property
    <style jsx global>{`
        .ant-notification-topRight {
            right: 50% !important;
            transform: translateX(50%);
            margin-right: 0px;
        }
        h1.ant-typography {
            font-size: 34px;
        }
        .ant-typography strong {
            font-weight: 500;
        }
        body {
            font-family: "Futura PT Book", sans-serif;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        strong,
        bold {
            font-family: "Futura PT", sans-serif;
        }
        p {
            margin: 0;
        }
        .ant-form-item {
            margin-bottom: 20px;
        }
    `}</style>
)
