import Cookies from "js-cookie"

export const AUTH_TOKEN_KEY = "authToken"
export const IMPERSONATOR_AUTH_TOKEN_KEY = "impersonatorToken"

export const getClientTokenFromCookies = (): string | undefined => {
    return getImpersonatorTokenFromCookies() || getAuthTokenFromCookies()
}

export const getAuthTokenFromCookies = (): string | undefined => {
    const token = Cookies.get(AUTH_TOKEN_KEY)

    if (token && token.length > 0) {
        return token
    }
}

export const getImpersonatorTokenFromCookies = (): string | undefined => {
    const token = Cookies.get(IMPERSONATOR_AUTH_TOKEN_KEY)

    if (token && token.length > 0) {
        return token
    }
}

export const setAuthTokenInCookies = (token: string) => {
    Cookies.set(AUTH_TOKEN_KEY, token)
}

export const removeAuthTokenFromCookies = () => {
    Cookies.remove(AUTH_TOKEN_KEY)
}

export const removeImpersonatorTokenFromCookies = () => {
    Cookies.remove(IMPERSONATOR_AUTH_TOKEN_KEY)
}

export const setImpersonatorTokenInCookies = (token: string) => {
    Cookies.set(IMPERSONATOR_AUTH_TOKEN_KEY, token)
}

export type SecureProps = { restrictredUserTypes?: ("candidate" | "hr")[] }
