export * from "./auth"
export * from "./number"
export * from "./dates"
export * from "./language"
export * from "./selectors"
export * from "./scalars"
export * from "./unpackQueryArray"

const ASSESSMENT_PATH = "/new/assessment"

export const getHostname = () =>
    window.location.hostname === "localhost"
        ? `${window.location.hostname}:${window.location.port}`
        : `https://${window.location.hostname}`

export const getPublicAssessmentUrl = (testId: string) => {
    return `${getHostname()}${ASSESSMENT_PATH}?id=${testId}`
}

export const typeformFormUrl = (typeformTestId: string) => {
    return `https://admin.typeform.com/form/${typeformTestId}/create`
}

export const getSetPasswordHrUrl = (token: string) => {
    const setPasswordUrl = new URL(`${getHostname()}/set_password`)
    setPasswordUrl.searchParams.append("resetToken", token)

    return setPasswordUrl
}
