import { useCallback, useEffect, useState } from "react"
import env from "@/env"
import { identifyUser } from "@/gtm"
import i18n from "@/i18n"
import { paths } from "@/paths"
import {
    getAuthTokenFromCookies,
    getClientTokenFromCookies,
    getHostname,
    getImpersonatorTokenFromCookies,
    removeAuthTokenFromCookies,
    removeImpersonatorTokenFromCookies,
    setAuthTokenInCookies,
    setImpersonatorTokenInCookies,
    unpackQueryArray,
} from "@/utils"
import { useApolloClient } from "@apollo/client"
import { scalars, useTypedMutation, useTypedQuery } from "new-api"
import { $, FromSelector, Selector } from "new-api/zeus"
import { NextRouter, useRouter } from "next/router"
import { createContainer } from "unstated-next"

const userSelector = Selector("User")({
    email: true,
    firstName: true,
    lastName: true,
    admin: true,
    uuid: true,
    locale: true,
    role: true,
    active: true,
    expiresOn: true,
    emailConfirmed: true,
    onboardingCompleted: true,
    account: {
        name: true,
        id: true,
        hasSubscription: true,
        expiresOn: true,
        tokensRemaining: true,
        billingModel: true,
        brandingEnabled: true,
        slots: true,
        slotsInUse: true,
    },
    accountLocale: true,
})

export type UserData = FromSelector<typeof userSelector, "User", typeof scalars>

export const HeroEvent = {
    hrTests: "Tests Page View",
    hrTest: "Test Page View",
    hrTestSolution: "Solution Page View",
    hrNewTestStep1: "New Test Page View",
    hrNewTestStep2: "New Test Competencies ",
    hrNewTestStep3: "New Test Questions",
    hrNewTestStep4: "New Test Created",
}

const getUserType = (
    userData: UserData | undefined,
    loadingUserData: boolean,
    router: NextRouter,
    checkedForCookieTokens: boolean
) => {
    if (loadingUserData || !router.isReady || !checkedForCookieTokens) {
        return "unknown"
    }

    if (!userData) {
        return "candidate"
    }

    if (userData.admin) {
        return "admin"
    }

    return "hr"
}

const useAuthContainer = createContainer(() => {
    const router = useRouter()
    const shareToken = unpackQueryArray(router.query.share)
    const queryLocale = unpackQueryArray(router.query.locale)

    const client = useApolloClient()

    const [checkedForCookieTokens, setCheckedForCookieTokens] = useState(false)
    const [authToken, setAuthToken] = useState<string | undefined>()
    const [impersonatorToken, setImpersonatorToken] = useState<string | undefined>()

    useEffect(() => {
        setAuthToken(getClientTokenFromCookies())
        setImpersonatorToken(getImpersonatorTokenFromCookies())
        setCheckedForCookieTokens(true)
    }, [])

    const hasAuthToken = !!authToken
    const isImpersonating = !!impersonatorToken
    const [urlBeforeLoginRedirect, setUrlBeforeLoginRedirect] = useState<string>()

    const {
        data,
        loading: loadingUserData,
        refetch: refetchUserData,
    } = useTypedQuery(
        {
            me: userSelector,
        },
        {
            operationOptions: {
                operationName: "Me",
            },
            apolloOptions: {
                skip: !router.isReady || !hasAuthToken,
                onCompleted: async ({ me }) => {
                    if (me) {
                        identifyUser(me)
                    }
                },
                onError: () => {
                    logout()
                },
            },
        }
    )

    const userData = data?.me

    const [testLanguage, setTestLanguage] = useState<string | null>(null)

    /*
        Rules for language selection:
        Test language > User langauge > Query language > Detected language
    */
    const locale = testLanguage ?? userData?.locale?.toLowerCase() ?? queryLocale ?? i18n.language

    useEffect(() => {
        if (locale) {
            i18n.changeLanguage(locale)
        }
    }, [locale])
    const userType = getUserType(userData, loadingUserData, router, checkedForCookieTokens)

    const shareMode = !!shareToken && !(userType === "admin" || userType === "hr")

    useEffect(() => {
        if (userData && !isImpersonating) {
            const pageHitObject = {
                user_id: userData.uuid,
                name: userData.firstName + " " + userData.lastName,
                email: userData.email,
                company: {
                    name: userData.account?.name,
                    company_id: userData.account?.id,
                },
            }
            if (window.UE?.pageHit) {
                window.UE.pageHit(pageHitObject)
            } else {
                console.log("pageHit", pageHitObject)
            }
        }
    }, [isImpersonating, userData])

    const logout = useCallback(async () => {
        window.UE?.resetAuth?.({
            apiKey: env.userDotComApiKey,
        })
        window.userengage?.("widget.hide")

        removeAuthTokenFromCookies()
        removeImpersonatorTokenFromCookies()
        setAuthToken(undefined)
        setImpersonatorToken(undefined)

        await client.resetStore()
        router.push(paths.login.go(), undefined, { shallow: true })
        router.reload()
    }, [router, client])

    const [authorizeUser] = useTypedMutation({
        auth: [
            {
                email: $("email", "String!"),
                password: $("password", "String!"),
            },
            {
                authorizationToken: true,
            },
        ],
    })

    const login = useCallback(
        async (email: string, password: string) => {
            const { data } = await authorizeUser({
                variables: {
                    email,
                    password,
                },
            })
            if (data?.auth?.authorizationToken) {
                setAuthToken(data?.auth?.authorizationToken)
                setAuthTokenInCookies(data?.auth?.authorizationToken)
                if (urlBeforeLoginRedirect) {
                    router.replace(urlBeforeLoginRedirect)
                } else {
                    router.push(paths.hrTests.go())
                }
            }
        },
        [authorizeUser, router, urlBeforeLoginRedirect]
    )

    const stopImpersonating = useCallback(async () => {
        removeImpersonatorTokenFromCookies()
        setImpersonatorToken(undefined)
        const authToken = getAuthTokenFromCookies()
        if (authToken) {
            await refetchUserData()
            router.push(paths.hrTests.go())
        } else {
            logout()
        }
    }, [logout, refetchUserData, router])

    const startImpersonatingTest = useCallback((token: string, testId: string) => {
        setImpersonatorTokenInCookies(token)
        setImpersonatorToken(token)
        window.open(`${getHostname()}/hr/test?id=${testId}`)
    }, [])

    const startImpersonatingUser = useCallback((token: string) => {
        setImpersonatorTokenInCookies(token)
        setImpersonatorToken(token)
        window.open(`${getHostname()}/hr/tests`)
    }, [])

    return {
        userType,
        loadingUserData,
        userData,
        refetchUserData,
        login,
        logout,
        locale,
        setTestLanguage,
        setUrlBeforeLoginRedirect,
        stopImpersonating,
        startImpersonatingTest,
        startImpersonatingUser,
        isImpersonating,
        shareMode,
        shareToken,
    }
})

export const AuthProvider = useAuthContainer.Provider
export const useAuth = useAuthContainer.useContainer
