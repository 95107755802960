import { UrlObject } from "url"

export const pathToString = (path: UrlObject) => {
    return `${window.location.origin}${path.pathname}${
        path.query
            ? "?" +
              Object.entries(path.query)
                  .map(([queryName, queryValue]) => `${queryName}=${queryValue}`)
                  .join("&")
            : ""
    }`
}

export const paths = {
    //////////////// HR
    registration: {
        pathname: "/registration",
        go: ({ locale }: { locale: "pl" | "en" }) => ({
            pathname: paths.registration.pathname,
            query: {
                locale,
            },
        }),
    },
    hrDashboard: {
        pathname: "/hr/tests",
        go: () => ({
            pathname: paths.hrDashboard.pathname,
            query: {},
        }),
    },
    hrTests: {
        pathname: "/hr/tests",
        go: ({ checkout, onboarding }: { checkout?: "success"; onboarding?: "true" } = {}) => ({
            pathname: paths.hrTests.pathname,
            query: {
                ...(checkout
                    ? {
                          checkout,
                      }
                    : {}),
                ...(onboarding
                    ? {
                          onboarding,
                      }
                    : {}),
            },
        }),
    },
    hrTest: {
        pathname: "/hr/test",
        go: ({ id }: { id: string }) => ({
            pathname: paths.hrTest.pathname,
            query: { id: id },
        }),
    },
    hrTestSolution: {
        pathname: "/hr/test",
        go: ({ id, solutionId, share }: { id: string; solutionId: string; share?: string }) => ({
            pathname: paths.hrTestSolution.pathname,
            query: { id, solutionId, ...(share ? { share } : {}) },
        }),
    },
    hrNewTest: {
        pathname: "/hr/newTest",
        go: () => ({
            pathname: paths.hrNewTest.pathname,
            query: {},
        }),
    },
    hrCreateAssessment: {
        pathname: "/hr/create-assessment",
        go: () => ({
            pathname: paths.hrCreateAssessment.pathname,
            query: {},
        }),
    },
    login: {
        pathname: "/login",
        go: () => ({
            pathname: paths.login.pathname,
            query: {},
        }),
    },
    reset_password: {
        pathname: "/reset_password",
        go: () => ({
            pathname: paths.reset_password.pathname,
            query: {},
        }),
    },
    setPassword: {
        pathname: "/set_password",
        go: ({ resetToken, onboarding }: { resetToken: string; onboarding?: string }) => ({
            pathname: paths.setPassword.pathname,
            query: {
                resetToken,
                ...(onboarding ? { onboarding } : undefined),
            },
        }),
    },
    billing: {
        pathname: "/billing",
        go: () => ({
            pathname: paths.billing.pathname,
            query: {},
        }),
    },
    branding: {
        pathname: "/branding",
        go: () => ({
            pathname: paths.branding.pathname,
            query: {},
        }),
    },
    pricing: {
        pathname: "/pricing",
        go: () => ({
            pathname: paths.pricing.pathname,
            query: {},
        }),
    },
    hrPricing: {
        pathname: "/hr/pricing",
        go: ({ checkout }: { checkout?: "cancelled" } = {}) => ({
            pathname: paths.hrPricing.pathname,
            query: checkout
                ? {
                      checkout,
                  }
                : {},
        }),
    },
    plan: {
        pathname: "/plan",
        go: ({ id, checkout }: { id: string; checkout?: "cancelled" }) => ({
            pathname: paths.plan.pathname,
            query: {
                id,
                ...(checkout ? { checkout } : {}),
            },
        }),
    },
    onboarding: {
        pathname: "/onboarding",
        go: () => ({
            pathname: paths.onboarding.pathname,
            query: {},
        }),
    },
    ///////// ADMIN
    adminTests: {
        pathname: "/administrador-secreto",
        relation: {
            type: "parent",
            key: "adminTest",
        },
        go: () => ({
            pathname: paths.adminTests.pathname,
            query: {},
        }),
    },
    adminUsers: {
        pathname: "/administrador-secreto/users",
        relation: {
            type: "parent",
            key: "adminUser",
        },
        go: () => ({
            pathname: paths.adminUsers.pathname,
            query: {},
        }),
    },
    adminUser: {
        pathname: "/administrador-secreto/user",
        go: ({ id }: { id: string }) => ({
            pathname: paths.adminUser.pathname,
            query: { id },
        }),
    },
    adminQuestions: {
        pathname: "/administrador-secreto/questions",
        go: () => ({
            pathname: paths.adminQuestions.pathname,
            query: {},
        }),
    },

    // todo: replace with better solution
    adminTest: {
        pathname: "/administrador-secreto/test",
        go: ({ id }: { id?: string } = {}) => ({
            pathname: paths.adminTest.pathname,
            query: id === undefined ? undefined : { id },
        }),
    },
    //////// CANDIDATE
    candidateTest: {
        pathname: "/test",
        go: ({ id }: { id?: string }) => ({
            pathname: paths.candidateTest.pathname,
            query: { id },
        }),
    },
    candidateAttempt: {
        pathname: "/attempt",
        go: ({ id }: { id: string }) => ({
            pathname: paths.candidateAttempt.pathname,
            query: { id },
        }),
    },
    candidateSolutionAttempt: {
        pathname: "/solution-attempt",
        go: ({ id }: { id: string }) => ({
            pathname: paths.candidateSolutionAttempt.pathname,
            query: { id },
        }),
    },
    assessmentResults: {
        pathname: "/assessment-results",
        go: () => ({
            pathname: paths.assessmentResults.pathname,
            query: {},
        }),
    },
    candidateSuccess: {
        pathname: "/success",
        go: ({
            id,
            candidateSessionToken,
            solutionId,
        }: {
            id: string
            candidateSessionToken: string
            solutionId: string
        }) => ({
            pathname: paths.candidateSuccess.pathname,
            query: { id, candidateSessionToken, solutionId },
        }),
    },
    notFound: {
        pathname: "/404",
        go: () => ({
            pathname: paths.notFound.pathname,
            query: {},
        }),
    },
    publicis: {
        notFound: {
            pathname: "/publicis/404",
            go: () => ({
                pathname: paths.publicis.notFound.pathname,
                query: {},
            }),
        },
        success: {
            pathname: "/publicis/success",
            go: ({ id }: { id: string }) => ({
                pathname: paths.publicis.success.pathname,
                query: { id },
            }),
        },
    },

    //EXTERNAL LINKS
    termsPL: {
        pathname: "https://docs.google.com/document/d/16VypUi_Orkn9TixUdf4VftE2KC2vqAOTEffwj3I_yt0/edit",
        go: () => ({
            pathname: paths.termsPL.pathname,
            query: {},
        }),
    },
    termsEN: {
        pathname: "https://docs.google.com/document/d/1k8ISBEIBc7QJlQlrIAPvmWl1a6b0h1RXMcadzza16vs/edit",
        go: () => ({
            pathname: paths.termsEN.pathname,
            query: {},
        }),
    },
    policyPL: {
        pathname: "https://docs.google.com/document/d/1fNQDF3krSHETZogAjCpbHmkMxJ-vsCOfd381n4js_eg/edit",
        go: () => ({
            pathname: paths.policyPL.pathname,
            query: {},
        }),
    },
    policyEN: {
        pathname: "https://docs.google.com/document/d/18NhQGQ9V4KNdCRfnZDTQD_QvjKmmU0Qm2gea6F_iFzk/edit",
        go: () => ({
            pathname: paths.policyEN.pathname,
            query: {},
        }),
    },
}
