import { IconName } from "./components/ui"
import env from "./env"

export const REGISTER_CANDIDATE_TIMER_WORKER = true

export const REDIRECT_TIMEOUT = 1000

export const API_URL = env.apiUrl
export const GOOGLE_ANALYTICS_LINK = "https://bit.ly/Demo-Analytics"
export const GOOGLE_ANALYTICS_4_LINK =
    "https://analytics.google.com/analytics/web/?utm_source=demoaccount&utm_medium=demoaccount&utm_campaign=demoaccount#/p213025502/reports/intelligenthome"
export const GOOGLE_SPREADSHEETS_LINK_PL =
    "https://docs.google.com/spreadsheets/d/1VttU67Z5adZUJ6-Q02Twt1ZphPWJVdomhRpjs2JalXg/edit?usp=sharing"
export const GOOGLE_SPREADSHEETS_LINK_EN =
    "https://docs.google.com/spreadsheets/d/13FhjaRuJoj-qZ27JAME0yBPNw_kv3xYObGMG8UZFiwU/edit?usp=sharing"
export const MICROSOFT_EXCEL_LINK_EN =
    "https://drive.google.com/uc?export=download&id=1PMTCL6EF_HkJxaPO2mzaDouULAc7uEDe"
export const MICROSOFT_EXCEL_LINK_PL =
    "https://drive.google.com/uc?export=download&id=1XVDrh9h5kdVnhkYsGtmO0v9OdkWbpH4F"
export const NEW_API_URL = env.newApiUrl

export const CANDIDATE_SOLUTION_ATTEMPT_DURATION_IN_MINUTES = 15
export const FILTERING_ASSESSMENT_DURATION_IN_MINUTES = 15

export const SITUATIONAL_ASSESSMENT_DURATION_IN_MINUTES = 6
export const VALIDATION_TEST_DURATION_IN_MINUTES = 15

export const RETRY_FAILED_REQUEST_DELAY_IN_MILLISECONDS = 2000

export const CANDIDATE_REGISTRATION_URL_PL = "https://form.typeform.com/to/qdMypHUq"
export const CANDIDATE_REGISTRATION_URL_EN = "https://form.typeform.com/to/wphhLIE8"

export const PercentScope = {
    good: {
        min: 76,
        max: 100,
    },
    average: {
        min: 51,
        max: 75,
    },
    bad: {
        min: 0,
        max: 50,
    },
}

export const skillIdsToIconNames: { [id: string]: IconName } = {
    "a169c7fe-f59f-4545-9b41-7874f8b8cb2c": "MarketingB2B",
    "38d564cb-e761-4a06-bee0-5f6b984887cf": "ContentMarketing",
    "59e04b3a-0812-4a71-ab57-4332d66c751f": "MarketingB2B",
    "06215bbf-0bd6-4911-86db-00e30e9e0a51": "Ecommerce",
    "22665dde-607d-489d-88f1-34d1f4a6a09b": "EmailMarketing",
    "2559041f-0fb3-4bec-8048-85f6510f62a7": "FacebookAds",
    "55c915ea-0ce5-4e9f-819b-99a51ed04af0": "GoogleAds",
    "ae09cdb4-08af-474c-877c-b6f2c4a963c1": "GoogleAnalytics",
    "d06cfdf5-b0c8-4b88-832f-45b1164a1430": "GoogleAnalytics",
    "dbaaefe3-0445-482a-8d28-ac8e96272b2d": "MarketingAutomation",
    "c87747b0-f815-45cf-81c7-7365d293fe4b": "Seo",
    "c190cb27-ac75-49af-8242-6e2f5bd37494": "SocialCampaing",
    "3bcb1141-7844-4d3b-8110-97fa08e36c2f": "LogicalThinking",
    "92d91c1a-5e19-424b-b527-03efaa22a54e": "AttentionToDetails",
    "53db0a14-4efd-4654-ac80-710d8e72ad25": "CognitiveSkills",
    "980eb712-84de-4512-a7bb-edf354097ca5": "MarketingB2B",
    "d0787595-4e7b-45c2-a117-f00c0812aed9": "EmailMarketing",
    "a2a04023-c2b1-480c-b422-8ab155fec61a": "MarketingB2B",
    "e882df2a-625e-419b-a42f-2aa7fc5a033d": "ContentMarketing",

    // Sales and business
    "79de3aba-0049-98b2-46fd-1d387247d349": "AccountExecutive",
    "144655c6-5b95-a5cb-0aff-bb0770b5c5ae": "Prospecting",
    "1b740545-722e-469c-8e8d-d74eae8d7977": "TalentAcquisition",

    // Work Mindset
    "ff55fbe1-56c8-4421-a31b-0878e15e6fc3": "ApproachToChallenges",
    "a1ef8616-354a-4ada-a23c-83eac8113135": "ApproachToMakingDecisions",
    "d04fb527-0604-452e-a0a0-072254f79473": "ApproachToUncertainty",
    "38daf416-1282-4f47-b069-9884c591bf00": "ApproachToWork",
}
