import { Flex } from "@/components/common/Flex"
import { IMPERSONATOR_BAR_HEIGHT } from "@/styles/utils"
import styled, { css } from "styled-components"

export const ImpersonatorBar = styled(Flex).attrs({ px: "10px", align: "center" })`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: ${IMPERSONATOR_BAR_HEIGHT}px;

    ${({ theme }) => css`
        background-color: ${theme.palette.custom.golden};
        z-index: ${theme.zIndexes.impersonatorBar};
    `};
`
