import { UserData } from "@/containers/authData"
import { Locale, TestLevel, TestType } from "new-api/zeus"

import { SignUpUser } from "./graphql/gen"

type CopyLinkLocationType = "successfully created test" | "copy link popup" | "active tests list" | "test page"
type CopyInvitationLocationType = "successfully created test" | "copy link popup"
type CreateNewTestLocationType = "header" | "active tests list" | "archive tests list"

declare global {
    interface Window {
        dataLayer: any
    }
}

const stepNumberToStepName: Record<number, string> = {
    1: "position",
    2: "test_skills",
    3: "open-ended_questions",
}

const testTypeToString: Record<TestType, string> = {
    VALIDATION: "Validation",
    FILTERING: "Filtering",
    ADVISORY: "Advisory",
}
const testLevelToString: Record<TestLevel, string> = {
    BASIC: "entry-level",
    ADVANCED: "specialist",
    MANAGER: "manager",
}

const pushToGTM = (obj: { [key: string]: any }) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push?.(obj)
}

export const wizardStepsGTM = (stepIndex: number) => {
    pushToGTM({
        event: "virtualPageView",
        stepNum: stepIndex,
        stepName: stepNumberToStepName[stepIndex],
        eventNonInteraction: false,
    })
}

export const wizardSuccessGTM = (testType: TestType, testLevel: TestLevel, estTime: number) => {
    pushToGTM({
        event: "virtualPageView",
        stepNum: 4,
        stepName: "success",
        expertise_level: testLevelToString[testLevel],
        test_type: testTypeToString[testType],
        est_time: estTime,
        eventNonInteraction: false,
    })
}

export const copyLinkGTM = (location: CopyLinkLocationType) => {
    pushToGTM({
        event: "GAEvent",
        eventCategory: "copy test link",
        eventAction: location,
        eventLabel: "",
        eventNonInteraction: false,
    })
}

export const copyInvitationGTM = (location: CopyInvitationLocationType) => {
    pushToGTM({
        event: "GAEvent",
        eventCategory: "copy test invitation",
        eventAction: location,
        eventLabel: "",
        eventNonInteraction: false,
    })
}

export const createNewTestGTM = (location: CreateNewTestLocationType) => {
    pushToGTM({
        event: "GAEvent",
        eventCategory: "create new test",
        eventAction: location,
        eventLabel: "",
        eventNonInteraction: false,
    })
}

export const identifyUser = (user: UserData) => {
    pushToGTM({
        event: "UserIdentified",
        userID: user.uuid,
        accountID: user.account.id,
        locale: user.locale,
        firstName: user.firstName,
        lastName: user.lastName,
        accountName: user.account.name,
        email: user.email,
        role: user.role,
    })
}

export const signUp = (user: SignUpUser) => {
    pushToGTM({
        event: "GAEvent",
        eventCategory: "early access sign up",
        eventAction: "success",
        eventLabel: "", // pozostaje puste
        userID: user.uuid, // id nadane zarejestrowanemu użytkownikowi
        accountID: user.accountId,
        eventNonInteraction: false,
    })
}

export const setLocale = (locale: Locale) => {
    pushToGTM({
        event: "SetLocale",
        locale: locale.toString(),
    })
}
