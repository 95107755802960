import { borderRadiusMd, createGlobalStyle } from "../theme"

export { notification } from "antd/lib"

export const NotificationStyles = createGlobalStyle`

    .ant-notification-notice {
        border-radius: ${borderRadiusMd};
    }
`
