import { Box } from "@/components/common/Box"
import styled, { css } from "styled-components"

type Justify = "space-between" | "space-evenly" | "space-around" | "center" | "flex-start" | "flex-end"
type Align = "center" | "flex-start" | "flex-end" | "baseline" | "stretch"

export type FlexProps = {
    justify?: Justify
    align?: Align
    col?: boolean
    $wrap?: boolean
    gap?: string | number
}

export const Flex = styled(Box)<FlexProps>`
    display: flex;

    ${p =>
        p.col &&
        css`
            flex-direction: column;
        `};

    ${p =>
        p.$wrap &&
        css`
            flex-wrap: wrap;
        `};

    ${p =>
        p.justify &&
        css`
            justify-content: ${p.justify};
        `}

    ${p =>
        p.align &&
        css`
            align-items: ${p.align};
        `}

    ${p =>
        p.gap &&
        css`
            gap: ${p.gap};
        `}
`
