import { createGlobalStyle } from "./styledComponents"
import { paletteNeutral900, primaryFont, spacing4 } from "."

export const GlobalStyles = createGlobalStyle`
    html, body, button, input, textarea {
        font-family: ${primaryFont}
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        margin-bottom: ${spacing4};
    }

    p {
        margin: 0;
    }

    p + p {
        margin-top: ${spacing4};
    }

    h1, h2, h3, h4, h5, h6, p, article, legend, li {
        color: ${paletteNeutral900};
    }
`
