import React from "react"
import { DefaultTheme, ThemeProvider as SCThemeProvider } from "styled-components"

import { primaryFont, styled, Theme, theme } from "./theme"
import { GlobalStyles } from "./theme/globals"
import { NotificationStyles } from "."

export type ThemeProviderProps = {
    children?: React.ReactNode
    isRoot?: boolean
    customTheme?: Theme
}

const CustomThemeContext = React.createContext<{
    customTheme: Theme | null
    setCustomTheme: (theme: Theme | null) => void
}>({ customTheme: null, setCustomTheme: () => {} })

export const useSetCustomTheme = () => {
    const { setCustomTheme } = React.useContext(CustomThemeContext)

    return setCustomTheme
}

export const UIProvider = ({ children, isRoot = false }: ThemeProviderProps) => {
    const [customTheme, setCustomTheme] = React.useState<Theme | null>(null)

    return (
        <CustomThemeContext.Provider value={{ customTheme, setCustomTheme }}>
            <SCThemeProvider theme={(customTheme ?? theme) as any as DefaultTheme}>
                {isRoot && (
                    <>
                        <GlobalStyles />
                        <NotificationStyles />
                        <div id="modal-portal-target" />
                    </>
                )}
                <Content>{children}</Content>
            </SCThemeProvider>
        </CustomThemeContext.Provider>
    )
}

const Content = styled.div`
    font-family: ${primaryFont};
    display: contents;
`
