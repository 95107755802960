import { Icon, IconName } from "@/components/ui"
import { styled } from "@/components/ui/theme"
import { keyframes } from "styled-components"

export type LoaderVariant = "light" | "dark"

export type LoaderProps = {
    variant?: LoaderVariant
    fullH?: boolean
    withoutPaddedWrapper?: boolean
}

export const Loader = ({ withoutPaddedWrapper = false, fullH = false, variant = "light" }: LoaderProps) => {
    const loaderIcon = <LoaderIcon name={variantToIconName[variant]} />

    if (withoutPaddedWrapper) return loaderIcon

    return <Wrapper fullH={fullH}>{loaderIcon}</Wrapper>
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const LoaderIcon = styled(Icon)`
    animation: ${rotate} 2s linear infinite;
`

const Wrapper = styled.div<{ fullH: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: ${p => (p.fullH ? "100vh" : "20vh")};
`

const variantToIconName: { [V in LoaderVariant]: IconName } = {
    light: "LoadingSpinnerOnLightBackground",
    dark: "LoadingSpinnerOnDarkBackground",
}
