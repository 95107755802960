import { initReactI18next } from "react-i18next"
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpAPI from "i18next-http-backend"

export const fallbackLng = "pl"

export const supportedLanguages = ["pl", "en"] as const
export type SupportedLanguage = typeof supportedLanguages[number]

i18n.use(HttpAPI)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        fallbackLng: [],
        supportedLngs: supportedLanguages,
        nonExplicitSupportedLngs: true,
        load: "languageOnly",
        ns: ["common"],
        defaultNS: "common",
        interpolation: {
            escapeValue: false,
        },
        debug: false,
        react: {
            useSuspense: false,
        },
        saveMissing: false,
    })

export default i18n
