export const theme = {
    palette: {
        primary: {
            "100": "#F0F3FC",
            "200": "#DCE2F9",
            "400": "#91A5EB",
            "500": "#002CCC",
            "700": "#1D1976",
            contrastText: "#FFFFFF",
        },
        secondary: {
            "100": "#EDFCF9",
            "200": "#C0E4DD",
            "400": "#70CEBC",
            "500": "#00AA89",
            "700": "#008A6F",
        },
        neutral: {
            white: "#ffffff",
            "100": "#F5F6FA",
            "200": "#DFE2F0",
            "300": "#B8BED9",
            "400": "#7D84A8",
            "500": "#646B8F",
            "700": "#3D425C",
            "900": "#1F2233",
        },
        error: {
            "100": "#FCEDED",
            "200": "#F6BDBD",
            "500": "#E05151",
            "600": "#C63131",
            "700": "#BD2020",
        },
        warning: {
            "100": "#FFF9EF",
            "400": "#FAE59D",
            "500": "#FFC963",
            "600": "#CB792D",
            "700": "#FFB400",
        },
    },
    spacing: {
        1: "4px",
        2: "8px",
        3: "12px",
        4: "16px",
        5: "20px",
        6: "24px",
        7: "28px",
        8: "32px",
        9: "36px",
        10: "40px",
        12: "48px",
        15: "60px",
        20: "80px",
    },
    typography: {
        font: {
            primary: "Rubik, sans-serif",
        },
        h1: {
            size: "40px",
            weight: 500,
        },
        h2: {
            size: "32px",
            weight: 500,
        },
        h3: {
            size: "24px",
            weight: 500,
        },
        h4: {
            size: "18px",
            weight: 500,
        },
        t1: {
            size: "16px",
            weight: 400,
        },
        t1b: {
            size: "16px",
            weight: 500,
        },
        t2: {
            size: "14px",
            weight: 400,
        },
        t2b: {
            size: "14px",
            weight: 500,
        },
        t3: {
            size: "12px",
            weight: 400,
        },
        t3b: {
            size: "12px",
            weight: 500,
        },
    },
    size: {
        borderRadius: {
            sm: "4px",
            md: "8px",
            lg: "16px",
        },
        icon: {
            small: "14px",
            general: "24px",
            large: "48px",
        },
    },
    breakpoints: {
        /* mobile */
        sm: "640px",
        /* tablet */
        md: "1024px",
        /* laptop */
        lg: "1440px",
        /* desktop */
    },
    boxShadow: {
        sm: "0px 1px 3px rgba(29, 25, 118, 0.12)",
        md: "0px 4px 8px rgba(29, 25, 118, 0.12)",
        lg: "0px 12px 24px rgba(29, 25, 118, 0.12)",
        xl: "0px 20px 32px rgba(29, 25, 118, 0.15)",
    },
    customLogo: null as string | null,
    components: {
        Header: {
            boxShadow: undefined as string | undefined,
            borderBottom: undefined as string | undefined,
        },
        Button: {
            boxShadow: undefined as string | undefined,
            borderWidth: undefined as string | undefined,
            textFontWeight: undefined as number | undefined,
            primary: {
                borderColor: undefined as string | undefined,
                hoverOutline: undefined as string | undefined,
            },
            secondary: {
                borderColor: undefined as string | undefined,
                hoverOutline: undefined as string | undefined,
            },
        },
        AnswerLabel: {
            activeBorderColor: undefined as string | undefined,
            activeBackgroundColor: undefined as string | undefined,
        },
        QuestionAndAnswer: {
            rowGap: undefined as string | undefined,
        },
        QuestionContent: {
            fontSize: undefined as string | undefined,
            fontWeight: undefined as number | undefined,
            textAlign: undefined as string | undefined,
        },
        QuestionLegend: {
            fontSize: undefined as string | undefined,
            fontWeight: undefined as number | undefined,
            color: undefined as string | undefined,
            textAlign: undefined as string | undefined,
        },
        Input: {
            borderColor: undefined as string | undefined,
            hoverBorderColor: undefined as string | undefined,
        },
        SmallStepper: {
            labelColor: undefined as string | undefined,
            itemBackgroundColor: undefined as string | undefined,
            activeItemBackgroundColor: undefined as string | undefined,
        },
    },
}

export type Theme = typeof theme
